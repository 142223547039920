// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: emptyStateStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const emptyStateStylesDefaultTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const emptyStateStylesDarkTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const emptyStateStylesTypography = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];
export const emptyStateStylesStructure = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const emptyStateStylesDefaultThemeOld = [
	{
		name: 'Header Text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Subline Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
];

export const emptyStateStylesDarkThemeOld = [
	{
		name: 'Header Text',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Subline Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'C8CDD1',
		isColorTile: true,
	},
];

export const emptyStateStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: 'Header Text',
		property: 'font-size',
		value: '30px',
		isColorTile: false,
	},
];

export const emptyStateStylesStructureOld = [
	{
		name: 'Container',
		property: 'width',
		value: '100%',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-top',
		value: '60px',
		isColorTile: false,
	},
	{
		name: 'Header Section',
		property: 'padding-bottom',
		value: '30px',
		isColorTile: false,
	},
	{
		name: 'Icon',
		property: 'padding-right',
		value: '8.5px',
		isColorTile: false,
	},
];
