// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: applicationMenuStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const applicationMenuStylesDefaultTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const applicationMenuStylesDarkTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const applicationMenuStylesTypography = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];
export const applicationMenuStylesStructure = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const applicationMenuStylesDefaultThemeOld = [
	{
		name: 'Background',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Text (Selected)',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon (Selected)',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Indicator Bar',
		property: 'border-color',
		value: 'FF5500',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF9966',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: 'FF9966',
		isColorTile: true,
	},
	{
		name: 'Dashed Border: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
];

export const applicationMenuStylesDarkThemeOld = [
	{
		name: 'Background',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Text (Selected)',
		property: 'color',
		value: '4EC3E0',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon (Selected)',
		property: 'color',
		value: '4EC3E0',
		isColorTile: true,
	},
	{
		name: 'Indicator Bar',
		property: 'border-color',
		value: '4EC3E0',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Dashed Border: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const applicationMenuStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
];

export const applicationMenuStylesStructureOld = [];
