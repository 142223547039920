// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: bannerStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const bannerStylesDefaultTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
];
export const bannerStylesDefaultTextButtonTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-focus',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Text: focus / active',
		property: 'color',
		value: '066C84',
		isColorTile: true,
	},
];
export const bannerStylesDefaultSecondaryTextButtonTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-focus',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Text: focus / active',
		property: 'color',
		value: '066C84',
		isColorTile: true,
	},
];
export const bannerStylesDefaultInformationalTheme = [
	{
		name: 'Indicator bar',
		property: 'border-top-color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
];
export const bannerStylesDefaultSuccessTheme = [
	{
		name: 'Indicator bar',
		property: 'border-top-color',
		value: '006E18',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '006E18',
		isColorTile: true,
	},
];
export const bannerStylesDefaultWarningTheme = [
	{
		name: 'Indicator bar',
		property: 'border-top-color',
		value: 'FF9C00',
		isColorTile: true,
	},
	{
		name: 'Icon (outer circle)',
		property: 'color',
		value: 'FF9C00',
		isColorTile: true,
	},
	{
		name: 'Icon (inner exclamation mark)',
		property: 'color',
		value: '000000',
		isColorTile: true,
	},
];
export const bannerStylesDefaultErrorTheme = [
	{
		name: 'Indicator bar',
		property: 'border-top-color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
];
export const bannerStylesDefaultAccordionTheme = [
	{
		name: 'Container',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Container header: hover',
		property: 'background-color',
		value: 'FFE6D9',
		isColorTile: true,
	},
	{
		name: 'Title text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
];
export const bannerStylesDarkTheme = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
];
export const bannerStylesDarkTextButtonTheme = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-focus',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Text: focus / active',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
];
export const bannerStylesDarkSecondaryTextButtonTheme = [
	{
		name: 'Text',
		property: 'color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-focus',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Text: focus / active',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
];
export const bannerStylesDarkInformationalTheme = [
	{
		name: 'Indicator bar',
		property: 'border-top-color',
		value: '6699CC',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '6699CC',
		isColorTile: true,
	},
];
export const bannerStylesDarkSuccessTheme = [
	{
		name: 'Indicator bar',
		property: 'border-top-color',
		value: '7FC5A2',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '7FC5A2',
		isColorTile: true,
	},
];
export const bannerStylesDarkWarningTheme = [
	{
		name: 'Indicator bar',
		property: 'border-top-color',
		value: 'F6C664',
		isColorTile: true,
	},
	{
		name: 'Icon (outer circle)',
		property: 'color',
		value: 'F6C664',
		isColorTile: true,
	},
];
export const bannerStylesDarkErrorTheme = [
	{
		name: 'Indicator bar',
		property: 'border-top-color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
];
export const bannerStylesDarkAccordionTheme = [
	{
		name: 'Container',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Container header: hover',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Title text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
];
export const bannerStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];
export const bannerStylesAccordionTypography = [
	{
		name: 'Header title text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '20px',
		isColorTile: false,
	},
];
export const bannerStylesStructure = [
	{
		name: 'Container',
		property: 'width',
		value: '100%',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-top',
		value: '4px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '4px',
		isColorTile: false,
	},
	{
		name: 'Container (indicator bar)',
		property: 'border-top',
		value: '4px',
		isColorTile: false,
	},
	{
		name: 'Icon',
		property: 'width',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
];
export const bannerStylesAccordionStructure = [
	{
		name: 'Container',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-top',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Header',
		property: 'padding-top',
		value: '4px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '4px',
		isColorTile: false,
	},
	{
		name: 'Content area',
		property: 'padding-top',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Icon',
		property: 'width',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '24px',
		isColorTile: false,
	},
	{
		name: 'Outline: focus (only header)',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'weight',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'dashes',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'gap',
		value: '2px',
		isColorTile: false,
	},
];
//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const bannerStylesDefaultThemeAll = [
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
];

export const bannerStylesDefaultThemeInfo = [
	{
		name: 'Indicator Bar',
		property: 'border-top-color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
];

export const bannerStylesDefaultThemeWarning = [
	{
		name: 'Indicator Bar',
		property: 'border-top-color',
		value: 'FF9C00',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'FF9C00',
		isColorTile: true,
	},
];

export const bannerStylesDefaultThemeError = [
	{
		name: 'Indicator Bar',
		property: 'border-top-color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
];

export const bannerStylesDarkThemeAll = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
];

export const bannerStylesDarkThemeInfo = [
	{
		name: 'Indicator Bar',
		property: 'border-top-color',
		value: '6699CC',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '6699CC',
		isColorTile: true,
	},
];

export const bannerStylesDarkThemeWarning = [
	{
		name: 'Indicator Bar',
		property: 'border-top-color',
		value: 'F6C664',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'F6C664',
		isColorTile: true,
	},
];

export const bannerStylesDarkThemeError = [
	{
		name: 'Indicator Bar',
		property: 'border-top-color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
];

export const bannerStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '14px',
		isColorTile: false,
	},
];

export const bannerStylesStructureOld = [
	{
		name: 'Message Text',
		property: 'max-width',
		value: '700px',
		isColorTile: false,
	},
	{
		name: 'Container',
		property: 'width',
		value: '100%',
		isColorTile: false,
	},
];
