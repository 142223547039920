// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: panelStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const panelStylesDefaultTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const panelStylesDarkTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const panelStylesTypography = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];
export const panelStylesStructure = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const panelStylesDefaultThemeOld = [
	{
		name: 'Border',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: false,
	},
];

export const panelStylesDarkThemeOld = [
	{
		name: 'Border',
		property: 'border-color',
		value: '425563',
		isColorTile: false,
	},
];

export const panelStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
];

export const panelStylesStructureOld = [
	{
		name: 'Panel',
		property: 'max-width',
		value: '620px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Toggle Icon',
		property: 'top',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'right',
		value: '2px',
		isColorTile: false,
	},
	{
		name: 'Panel Top',
		property: 'padding-top',
		value: '20px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Panel Content',
		property: 'height',
		value: '100%',
		isColorTile: false,
	},
];
