<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<app-external-resource [componentName]="'Drop-down'" [includeDescription]="true"
			[otherNames]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<section class="page-section-content">
			The live example for this section will be in an upcoming release.
		</section>
		<!-- <form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change example">
					<option value="default">First sample live example</option>
					<option value="second">Second sample live example</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<div class="page-live-example" [class.page-live-show]="currLive === 'default'">
					First live example content
				</div>
				<div class="page-live-example" [class.page-live-show]="currLive === 'second'">
					Second live example content
				</div>
			</div>
		</form> -->
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<section class="page-section-content">
			The developer resources for this section will be in an upcoming release.
		</section>
		<!-- <section class="page-sub-section" id="dev-started">
			<h3 id="subSectionTitleDevStarted" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-started')">
				Getting started<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Text placeholder
				</li>
			</ul>
		</section> -->
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<pre prism class="dark">{{ dropDownHTML }}</pre>
		</section>
		<section class="page-sub-section" id="dev-api">
			<h3 id="subSectionTitleDevApi" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')">
				API<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/dropdowns/api/DropDownListComponent/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo DropDownList API</a>
				</li>
				<li class="page-section-list-item">
					Refer to the
					<a href="components/dropDown#behaviors">behaviors section</a>
					to see supported component features.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<span class="page-style-list-title">Subtitle placeholder</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<section class="page-sub-section" id="behaviors-select">
			<h3 id="subSectionTitleBehaviorsSelect" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-select')">
				Select<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Drop-downs appear as rectangular fields. There's a downward arrow inside the field and to the right.
					The icon does not change when the drop-down is open (unlike accordions).
				</li>
				<li class="page-section-list-item">
					Focusing or selecting the rectangle will open the drop-down list of items. If there’s a selected
					value, it shows as selected in the list of items.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-focus">
			<h3 id="subSectionTitleBehaviorsFocus" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-focus')">
				Focus<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Focusing or selecting the rectangle opens the drop-down. Removing focus on the rectangle, like
					navigating or tabbing away, closes the drop-down.
					The focus treatment is applied to just the rectangle, not the list. When the drop-down is open,
					focus switches to the typeahead <a href="components/text-field">text field</a>.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-disable">
			<h3 id="subSectionTitleBehaviorsDisable" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-disable')">
				Disable<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Disabled drop-downs have 50% opacity. The field cannot be selected, focused, or interacted with in
					any way. A disabled field appears unpopulated if the field was originally empty or populated if
					originally filled.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-typehead">
			<h3 id="subSectionTitleBehaviorsTypehead" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-typehead')">
				Typeahead (keypress)<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					When a drop-down list opens, there’s a text field above the list of items that’s used to filter the
					list. The focus is on this text field so the user can immediately start filtering. After closing a
					drop-down list, any filter is cleared.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-error">
			<h3 id="subSectionTitleBehaviorsError" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-error')">
				Error<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Error text can be shown under the drop-down field, similar to <a href="components/text-field">text
						field</a>. It shouldn’t be wider than
					the control and wraps if necessary.
					Drop-downs in an error state have a different colored border. When the error condition is fixed, the
					border returns to normal and the error message is removed.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-help">
			<h3 id="subSectionTitleBehaviorsHelp" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-help')">
				Help text<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Similar to <a href="components/text-field">text fields</a>, a <a href="components/help-text">help
						text</a> icon can be used after the label.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-tooltip">
			<h3 id="subSectionTitleBehaviorsTooltip" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-tooltip')">
				Tooltip<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Similar to <a href="components/text-field">text
						fields</a>, a <a href="components/tooltip">tooltip</a> can be used after the label.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-empty">
			<h3 id="subSectionTitleBehaviorsEmpty" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-empty')">
				Empty state<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					If the typeahead filter doesn’t show any options, the text “No options found.” is displayed.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-instructional">
			<h3 id="subSectionTitleBehaviorsInstructional" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-instructional')">
				Instructional text<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Instructional text can be displayed below the drop-down field. This is used for short and helpful
					messages to help the user know what to select. It shouldn’t be wider than the control and wraps if
					necessary.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="variations">
		<h2 id="sectionTitleVariations" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('variations')">
			Variations<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<section class="page-sub-section" id="variations-default">
			<h3 id="subSectionTitleVariationsDefault" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-default')">
				Default<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A list of options with no grouping of options or supporting content.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-grouped">
			<h3 id="subSectionTitleVariationsGrouped" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-Grouped')">
				Grouped<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Options in a drop-down are able to be grouped. This header is not selectable. These should be
					alphabetically listed, unless there is another logical order to present them.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-support">
			<h3 id="subSectionTitleVariationsSupport" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-support')">
				Supporting content<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A short secondary line of text can be placed under the main drop-down list item text to help users
					better understand an option and make a decision.
					For example, a person’s name as the option and their email as the supporting content below.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<section class="page-sub-section" id="usage-general">
			<h3 id="subSectionTitleUsageGeneral" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-general')">
				General<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-do-list-title"> Do use drop-downs:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							If a user has many options to choose from
						</li>
						<li class="page-section-list-item">
							If a user can only select one option
						</li>
						<li class="page-section-list-item">
							If you want to give users the ability to filter through options with typeahead
						</li>
					</ul>
				</li>
				<li class="page-section-list-item" style="margin-top: 5px">
					<span class="page-section-do-list-title">Don’t use drop-downs:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							If you want to give the users the ability to add new options, not just select pre-existing
							ones — use a combo box instead
						</li>
						<li class="page-section-list-item">
							If there are only 2-3 options — use <a href="components/radio-button">radio buttons</a>
							instead
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<span class="page-section-do-list-title">Labels</span>
				<li class="page-section-list-item">
					Drop-down labels should be short, 1-2 words, usually nouns. Users know what to do with a drop-down,
					so words like “select” or “choose” are typically not needed.
				</li>
				<ul class="page-section-comparison-list">
					<li class="page-section-comparison-list-item comparison-do">
						<div class="page-section-comparison-header">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
								width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
								<title>Icon/Status/checkmark</title>
								<g id="Icon/Status/checkmark" stroke="none" stroke-width="1" fill="none"
									fill-rule="evenodd">
									<path
										d="M20.6534688,4.16908529 C20.8789629,3.94363824 21.2445273,3.94363824 21.4700213,4.16908529 L22.8308795,5.52999044 C23.0563735,5.75548448 23.0563735,6.12109588 22.8308795,6.34654293 L8.40527544,20.772194 C8.17973441,20.997641 7.81417,20.997641 7.58872295,20.772194 L1.16912053,14.3525915 C0.94362649,14.1270505 0.94362649,13.7614861 1.16912053,13.5359921 L2.5299787,12.1751339 C2.75551972,11.9496398 3.12108413,11.9496398 3.34657817,12.1751339 L7.99699919,16.8255549 L20.6534688,4.16908529 Z"
										id="" fill="#000000" />
								</g>
							</svg>
							Try This
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Operating System
								</li>
							</ul>
						</div>
					</li>
					<li class="page-section-comparison-list-item comparison-do-not">
						<div class="page-section-comparison-header">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
								width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
								<title>Icon/NavObjects/close</title>
								<g id="Icon/NavObjects/close" stroke="none" stroke-width="1" fill="none"
									fill-rule="evenodd">
									<path
										d="M21.4999535,10.2499803 C22.3282291,10.2499803 22.9999499,10.9217011 22.9999499,11.7499768 L22.9999499,13.2499732 C22.9999499,14.0782488 22.3282291,14.7499696 21.4999535,14.7499696 L14.7499696,14.7499696 L14.7499696,21.4999535 C14.7499696,22.3282291 14.0782488,22.9999499 13.2499732,22.9999499 L11.7499768,22.9999499 C10.9217011,22.9999499 10.2499803,22.3282291 10.2499803,21.4999535 L10.2499803,14.7499696 L3.5,14.7499696 C2.67172081,14.7499696 2,14.0782488 2,13.2499732 L2,11.7499768 C2,10.9217011 2.67172081,10.2499803 3.5,10.2499803 L10.2499803,10.2499803 L10.2499803,3.5 C10.2499803,2.67172081 10.9217011,2 11.7499768,2 L13.2499732,2 C14.0782488,2 14.7499696,2.67172081 14.7499696,3.5 L14.7499696,10.2499803 L21.4999535,10.2499803 Z"
										id="add2" fill="#000000"
										transform="translate(12.499975, 12.499975) rotate(-315.000000) translate(-12.499975, -12.499975) " />
								</g>
							</svg>
							Not This
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Select an Operating System
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</ul>
			<ul class="page-section-list">
				<span class="page-section-do-list-title">Instructional Text</span>
				<li class="page-section-list-item">
					Drop-down instructional text shouldn’t repeat the label, or just tell the user to select or choose
					something. Drop-down instructional text should offer additional information that the label doesn’t.
				</li>
				<ul class="page-section-comparison-list">
					<li class="page-section-comparison-list-item comparison-do">
						<div class="page-section-comparison-header">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
								width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
								<title>Icon/Status/checkmark</title>
								<g id="Icon/Status/checkmark" stroke="none" stroke-width="1" fill="none"
									fill-rule="evenodd">
									<path
										d="M20.6534688,4.16908529 C20.8789629,3.94363824 21.2445273,3.94363824 21.4700213,4.16908529 L22.8308795,5.52999044 C23.0563735,5.75548448 23.0563735,6.12109588 22.8308795,6.34654293 L8.40527544,20.772194 C8.17973441,20.997641 7.81417,20.997641 7.58872295,20.772194 L1.16912053,14.3525915 C0.94362649,14.1270505 0.94362649,13.7614861 1.16912053,13.5359921 L2.5299787,12.1751339 C2.75551972,11.9496398 3.12108413,11.9496398 3.34657817,12.1751339 L7.99699919,16.8255549 L20.6534688,4.16908529 Z"
										id="" fill="#000000" />
								</g>
							</svg>
							Try This
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Workgroup
								</li>
								<li class="comparison-body-text-list-item">
									Assign managed accounts to an existing workgroup.
								</li>
							</ul>
						</div>
					</li>
					<li class="page-section-comparison-list-item comparison-do-not">
						<div class="page-section-comparison-header">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
								width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
								<title>Icon/NavObjects/close</title>
								<g id="Icon/NavObjects/close" stroke="none" stroke-width="1" fill="none"
									fill-rule="evenodd">
									<path
										d="M21.4999535,10.2499803 C22.3282291,10.2499803 22.9999499,10.9217011 22.9999499,11.7499768 L22.9999499,13.2499732 C22.9999499,14.0782488 22.3282291,14.7499696 21.4999535,14.7499696 L14.7499696,14.7499696 L14.7499696,21.4999535 C14.7499696,22.3282291 14.0782488,22.9999499 13.2499732,22.9999499 L11.7499768,22.9999499 C10.9217011,22.9999499 10.2499803,22.3282291 10.2499803,21.4999535 L10.2499803,14.7499696 L3.5,14.7499696 C2.67172081,14.7499696 2,14.0782488 2,13.2499732 L2,11.7499768 C2,10.9217011 2.67172081,10.2499803 3.5,10.2499803 L10.2499803,10.2499803 L10.2499803,3.5 C10.2499803,2.67172081 10.9217011,2 11.7499768,2 L13.2499732,2 C14.0782488,2 14.7499696,2.67172081 14.7499696,3.5 L14.7499696,10.2499803 L21.4999535,10.2499803 Z"
										id="add2" fill="#000000"
										transform="translate(12.499975, 12.499975) rotate(-315.000000) translate(-12.499975, -12.499975) " />
								</g>
							</svg>
							Not This
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Workgroup
								</li>
								<li class="comparison-body-text-list-item">
									Choose a workgroup.
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</ul>
			<ul class="page-section-list">
				<span class="page-section-do-list-title">Help text and tooltips</span>
				<li class="page-section-list-item">
					Drop-down <a href="components/tooltip">tooltips</a> can be used to help define terms that users
					might not know. Drop-down <a href="components/help-text">help text</a>
					can be used for longer instructions if the workflow is particularly complicated.
					Neither should contain any critical information the user needs to complete a task.
				</li>
				<li class="page-section-list-item">
					Here is an example of help text:
				</li>
				<ul class="page-section-comparison-list">
					<li class="page-section-comparison-list-item comparison-do">
						<div class="page-section-comparison-header">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
								width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
								<title>Icon/Status/checkmark</title>
								<g id="Icon/Status/checkmark" stroke="none" stroke-width="1" fill="none"
									fill-rule="evenodd">
									<path
										d="M20.6534688,4.16908529 C20.8789629,3.94363824 21.2445273,3.94363824 21.4700213,4.16908529 L22.8308795,5.52999044 C23.0563735,5.75548448 23.0563735,6.12109588 22.8308795,6.34654293 L8.40527544,20.772194 C8.17973441,20.997641 7.81417,20.997641 7.58872295,20.772194 L1.16912053,14.3525915 C0.94362649,14.1270505 0.94362649,13.7614861 1.16912053,13.5359921 L2.5299787,12.1751339 C2.75551972,11.9496398 3.12108413,11.9496398 3.34657817,12.1751339 L7.99699919,16.8255549 L20.6534688,4.16908529 Z"
										id="" fill="#000000" />
								</g>
							</svg>
							Try This
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Rules help automate how you handle detections and recommendations. For example, you
									can create rules to exclude certain detections and recommendations from your
									dashboard, or rules to send notifications for high or critical ones.
								</li>
							</ul>
						</div>
					</li>
					<li class="page-section-comparison-list-item comparison-do-not">
						<div class="page-section-comparison-header">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
								width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
								<title>Icon/NavObjects/close</title>
								<g id="Icon/NavObjects/close" stroke="none" stroke-width="1" fill="none"
									fill-rule="evenodd">
									<path
										d="M21.4999535,10.2499803 C22.3282291,10.2499803 22.9999499,10.9217011 22.9999499,11.7499768 L22.9999499,13.2499732 C22.9999499,14.0782488 22.3282291,14.7499696 21.4999535,14.7499696 L14.7499696,14.7499696 L14.7499696,21.4999535 C14.7499696,22.3282291 14.0782488,22.9999499 13.2499732,22.9999499 L11.7499768,22.9999499 C10.9217011,22.9999499 10.2499803,22.3282291 10.2499803,21.4999535 L10.2499803,14.7499696 L3.5,14.7499696 C2.67172081,14.7499696 2,14.0782488 2,13.2499732 L2,11.7499768 C2,10.9217011 2.67172081,10.2499803 3.5,10.2499803 L10.2499803,10.2499803 L10.2499803,3.5 C10.2499803,2.67172081 10.9217011,2 11.7499768,2 L13.2499732,2 C14.0782488,2 14.7499696,2.67172081 14.7499696,3.5 L14.7499696,10.2499803 L21.4999535,10.2499803 Z"
										id="add2" fill="#000000"
										transform="translate(12.499975, 12.499975) rotate(-315.000000) translate(-12.499975, -12.499975) " />
								</g>
							</svg>
							Not This
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									All of the following conditions need to be met to create a rule.
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</ul>
			<ul class="page-section-list">
				<span class="page-section-do-list-title">Options and grouping</span>
				<li class="page-section-list-item">
					Drop-down options should be informative and quickly understood – their meaning and implication
					should be clear from their name. They should also be distinguishable from one another at a glance.
				</li>
				<ul class="page-section-comparison-list">
					<li class="page-section-comparison-list-item comparison-do">
						<div class="page-section-comparison-header">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
								width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
								<title>Icon/Status/checkmark</title>
								<g id="Icon/Status/checkmark" stroke="none" stroke-width="1" fill="none"
									fill-rule="evenodd">
									<path
										d="M20.6534688,4.16908529 C20.8789629,3.94363824 21.2445273,3.94363824 21.4700213,4.16908529 L22.8308795,5.52999044 C23.0563735,5.75548448 23.0563735,6.12109588 22.8308795,6.34654293 L8.40527544,20.772194 C8.17973441,20.997641 7.81417,20.997641 7.58872295,20.772194 L1.16912053,14.3525915 C0.94362649,14.1270505 0.94362649,13.7614861 1.16912053,13.5359921 L2.5299787,12.1751339 C2.75551972,11.9496398 3.12108413,11.9496398 3.34657817,12.1751339 L7.99699919,16.8255549 L20.6534688,4.16908529 Z"
										id="" fill="#000000" />
								</g>
							</svg>
							Try This
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Workday
								</li>
								<li class="comparison-body-text-list-item">
									Weekend
								</li>
							</ul>
						</div>
					</li>
					<li class="page-section-comparison-list-item comparison-do-not">
						<div class="page-section-comparison-header">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
								width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
								<title>Icon/NavObjects/close</title>
								<g id="Icon/NavObjects/close" stroke="none" stroke-width="1" fill="none"
									fill-rule="evenodd">
									<path
										d="M21.4999535,10.2499803 C22.3282291,10.2499803 22.9999499,10.9217011 22.9999499,11.7499768 L22.9999499,13.2499732 C22.9999499,14.0782488 22.3282291,14.7499696 21.4999535,14.7499696 L14.7499696,14.7499696 L14.7499696,21.4999535 C14.7499696,22.3282291 14.0782488,22.9999499 13.2499732,22.9999499 L11.7499768,22.9999499 C10.9217011,22.9999499 10.2499803,22.3282291 10.2499803,21.4999535 L10.2499803,14.7499696 L3.5,14.7499696 C2.67172081,14.7499696 2,14.0782488 2,13.2499732 L2,11.7499768 C2,10.9217011 2.67172081,10.2499803 3.5,10.2499803 L10.2499803,10.2499803 L10.2499803,3.5 C10.2499803,2.67172081 10.9217011,2 11.7499768,2 L13.2499732,2 C14.0782488,2 14.7499696,2.67172081 14.7499696,3.5 L14.7499696,10.2499803 L21.4999535,10.2499803 Z"
										id="add2" fill="#000000"
										transform="translate(12.499975, 12.499975) rotate(-315.000000) translate(-12.499975, -12.499975) " />
								</g>
							</svg>
							Not This
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Weekday
								</li>
								<li class="comparison-body-text-list-item">
									Weekend day
								</li>
							</ul>
						</div>
					</li>
				</ul>
				<li class="page-section-list-item">
					Options should be logically ordered. The typical order is alphabetical. Options can be grouped
					together and separated using a line, or a line and category label. Avoid nesting options. There
					should only ever be one level of categorization.
				</li>
				<li class="page-section-list-item">
					Option names should not be so long they require wrapping or truncation — keep to a single line of
					text. There can be a secondary line below the option name with more information. This secondary line
					is in a smaller font size.
				</li>
				<li class="page-section-list-item">
					Drop-down fields should not have pre-selected options. If “None” is an option, then it can
					be the default selection.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/help-text">Help text component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/icon">Icon component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/number-field">Number field component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/radio-button">Radio button component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/tooltip">Tooltip component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography component</a>
			</li>
		</ul>
		<span class="page-related-list-title">Components and patterns that use
			drop-downs:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/data-grid">Data grid component</a>
			</li>
			<li class="page-related-list-item">
				<a href="patterns/filter">Filter pattern</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Drop-down Component'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
