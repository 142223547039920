// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: formStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const formStylesDefaultTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const formStylesDarkTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const formStylesTypography = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];
export const formStylesStructure = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const formStylesDefaultThemeOld = [
	{
		name: 'Header Text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Label/Input Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
];

export const formStylesDarkThemeOld = [
	{
		name: 'Header Text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Label Text',
		property: 'color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Input Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
];

export const formStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: 'Input Text',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: 'Label Text',
		property: 'font-size',
		value: '12px',
		isColorTile: false,
	},
	{
		name: 'Header Text (H2)',
		property: 'font-size',
		value: '20px',
		isColorTile: false,
	},
	{
		name: 'Header Text (H3)',
		property: 'font-size',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
];

export const formStylesStructureOld = [
	{
		name: 'Form Section',
		property: 'margin-top',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-bottom',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Form Component',
		property: 'margin-top',
		value: '16px',
		isColorTile: false,
	},
];
